//@ts-ignore
let $ = jQuery || window.jQuery


$(window).on("load", () => {
	let content_elements = Array.from($("#competency-content").children())
	let title_elements = Array.from($("#competency-titles").children())
	title_elements.forEach((child) => {
		let child_el = $(child)
		child_el.on("click", () => {
			title_elements.forEach((t_el) => {
				let title_element = $(t_el).children("div")
				if (title_element.hasClass("active")){
					title_element.removeClass("active")
				}
			})
			content_elements.forEach((content) => {
				let content_element = $(content)
				if (!content_element.hasClass("hidden")){
					content_element.addClass("hidden")
				}
			})
			$("#" + child_el.attr("data-target")).removeClass("hidden")
			child_el.children().addClass("active")
		})
	})
})

$(window).on("load", () => {
	$(".mobile-competency").each((idx, comp) => {
		let competency = $(comp)
		let content = $(competency.children(".mobile-competency-content").get(0));
		let title = $(competency.children(".mobile-competency-title").get(0));
		let chevron = $(title).children(".competency-mobile-chevron")

		$( window ).on("resize",function() {
			if ($(content).height() > 0){
				let autoHeight = $(content).css('height', 'auto').height();
				$(content).height(autoHeight);
			}
		})

		$(title).on("click",function(){
			if ($(content).height() > 0) {
				chevron.removeClass("rotate-180")
				$(content).animate({
					height: '0'
				}, 400);
			}else {
				chevron.addClass("rotate-180")
				$(content).animate({
					height: $(content)[0].scrollHeight + 'px'
				}, 400);
			}
		});
	})
})
